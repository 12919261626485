<template>
  <div v-if="active" class="overlay" @click.self="cancelModal">
    <div class="dialog message" v-if="isMessage">
      <p class="message-wrapper">{{ message }}</p>
      <div class="toolbar">
        <button v-if="isConfirm" type="submit" @click="cancelModal">
          Abbrechen
        </button>
        <button class="primary" type="submit" @click="confirmModal">
          Bestätigen
        </button>
      </div>
    </div>
    <div v-else class="dialog">
      <div class="title-bar">
        <button class="transparentButton" @click="cancelModal"><svg-icon :fa-icon="faRectangleXmark"></svg-icon></button>
      </div>
      <component :is="component" v-model="componentData" class="message-wrapper" @close="confirmModal"></component>
      <div class="toolbar" v-if="isConfirm">
        <button class="transparentButton" @click="cancelModal">
          <svg-icon :fa-icon="faRectangleXmark"></svg-icon>
        </button>
        <button class="primary" @click="confirmModal">
          Bestätigen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "vue3-icon";
import {faRectangleXmark} from '@fortawesome/free-solid-svg-icons';
import {markRaw, shallowRef} from "@vue/reactivity";

export default {
  name: "ModalOverlay",
  components:{SvgIcon},
  data() {
    return {
      faRectangleXmark,
      isConfirm:false,
      active: false,
      message: '',
      confirmCallBack: null,
      component: null,
      componentData: null

    };
  },
  created() {
    this.emitter.on('modal-requested', (e) =>{
      this.openModal(e.message)
    });
    this.emitter.on('confirm-requested', (e) =>{
      this.openConfirm(e.component,e.callback,e.data)
    });
    this.emitter.on('modal-component-requested', (e) =>{
      this.openComponent(e.component,e.callback,e.data)
    });
    this.emitter.on('modal-page-requested', (e) =>{
      this.openPage(e.component,e.callback,e.data)
    });



  },
  methods: {
    openModal(msg, callback) {
      this.confirmCallBack = typeof callback === 'undefined' ? null : callback;
      this.message = msg;
      this.active = true;
    },
    confirmModal() {
      if (this.confirmCallBack !== null) {
        this.confirmCallBack(this.componentData);
      }
      this.isConfirm=false;
      this.active = false;
      this.message = '';
      this.component = null;
      this.componentData = null;
    },
    cancelModal() {
      this.confirmCallBack = null;
      this.active = false;
      this.message = '';
      this.component = null;
    },
    openConfirm(msg, callback) {
      this.isConfirm=true;
      this.confirmCallBack = callback;
      this.message = msg;
      this.active = true;
    },
    openComponent(component, callback) {
      this.confirmCallBack = typeof callback === 'undefined' ? null : callback;
      this.component = component;
      this.active = true;
    },
    openPage(component, callback,componentData){
      this.confirmCallBack = typeof callback === 'undefined' ? null : callback;
      this.componentData = typeof componentData === 'undefined' ? null : componentData;
      this.component = shallowRef(component);
      this.active = true;
    }
  },
  computed: {
    isMessage() {
      return this.component === null && this.message;
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/FrontEnd/styles/input";
.transparentButton {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  background-color: rgba(255,255,255,.6);
}
.dialog {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  width: 90vw;
  max-width: 40rem;
  min-height: 18rem;
  max-height: 90vh;
  border: .1rem solid #ddd;
  background: #fff;
  box-shadow: .2rem 0 .4rem rgba(100, 100, 100, .6);
  overflow-y: auto;


&.message {
   display: flex;
 }

& .toolbar {
  @include tool-bar;
    position: absolute;
    bottom: 0;
  }
& .title-bar {
  @include tool-bar;
    position: sticky;
    top: 0
  }
& .close>* {
    margin: 0 0.25rem;
  }
&>.message-wrapper {
   box-sizing: border-box;
   padding: 0.5rem;
 }
&>p.message-wrapper {
   text-align: center;
   font-size: 1.2rem;
   flex: 2;
   align-self: center;
 }
}

</style>
