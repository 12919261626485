<template>
  <div>
    <router-view></router-view>
    <modal-overlay></modal-overlay>
  </div>
</template>
<script>
import ModalOverlay from "@/FrontEnd/components/ModalOverlay";
export default {
  name: 'App',
  components: {ModalOverlay}
}
</script>

<style>
</style>
