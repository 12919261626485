import { createApp } from 'vue'
import App from '@/FrontEnd/App.vue'
import router from '@/FrontEnd/router'
import store from '@/FrontEnd/store/store.js'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import SvgIcon from "vue3-icon";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import mitt from 'mitt';

const emitter = mitt();

const app = createApp(App);
app.component("svg-icon", SvgIcon);
app.component("vue-select", VueSelect);
app.config.globalProperties.emitter = emitter;
app.use(store).use(router).mount('#app')
