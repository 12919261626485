
import router from "@/FrontEnd/router"
import createPersistedState from 'vuex-persistedstate'

import {createStore} from 'vuex';



const store = createStore({
  state: {
    sessionToken: null,
    authToken: null,
    authRedirect: null,
    user: null,
    sessionValidUntil:null
  },
  mutations: {
    login (state, {token, auth,user}) {
      state.sessionToken = token;
      state.authToken = auth;
      state.user = user;
      let date = new Date();
      date = new Date(date.getTime() + 60*60000);
      let dateString = date.getTime();
      state.sessionValidUntil=dateString;

      localStorage.setItem('sessionToken', state.auth);
      localStorage.setItem('session', state.sessionToken);
      localStorage.setItem('user', JSON.stringify(state.user));
      localStorage.setItem('sessionValidUntil', dateString.toString());
    },
    logout (state) {
      state.sessionToken = null;
      state.sessionValidUntil = null;
      localStorage.removeItem('session');
      localStorage.removeItem('sessionValidUntil');
    },
    setRedirect (state, redirect) {
      state.authRedirect = redirect;
    },
    init (state) {
      // Look for data in local storage
      if (localStorage.getItem('session')) {
        state.sessionToken = localStorage.getItem('session');
      }
      if (localStorage.getItem('user')) {
        state.user = JSON.parse(localStorage.getItem('user'));
      }

      // If data is incomplete, reset all
      if (!state.sessionToken || !state.user) {
        state.sessionToken = null;
        state.user = null;
      }
    }
  },
  getters: {
    isSessionValid: state => {
        let now = new Date();
        let nowString = now.getTime();

        return Number(state.sessionValidUntil) > nowString;
    },
    auth: state => {
      return {
        headers: { Authorization: `Bearer `+ state.authToken }
      }
    },
    loggedIn: state => {
      return state.sessionToken !== null;
    },
    userName: state => {
      return state.user.name;
    }
  },
  actions: {
    logout({commit, dispatch}, ref) {
      commit('logout');
      dispatch('redirectToLogin', ref);
    },
    login({commit, state}, data) {
      commit('login', data);
      router.push(state.authRedirect ? state.authRedirect : {name: 'dashboard'}).catch(()=>{});
      // reset auth redirect for next run
      commit('setRedirect', null);
    },
    redirectToLogin({commit}, ref) {
      commit('setRedirect', ref);
      router.push({ name: 'login'});
    }
  },
  plugins: [createPersistedState()]
});

export function adminNavGuard(to, from, next) {
  if (!store.getters.loggedIn) {
    //Save target for redirect if neither login nor root
    if (to.name !== 'login' && to.name !== 'dashboard') {
      store.commit('setRedirect', to);
    }
    next({ name: 'login' });
    return;
  }
  next();
}

export function userNavGuard(to, from, next) {
  if (!store.getters.loggedIn) {
    //Save target for redirect if neither login nor root
    if (to.name !== 'login' && to.name !== 'dashboard') {
      store.commit('setRedirect', to);
    }
    next({ name: 'login' });
    return;
  }
  next();
}

export function rootHubNavGuard(to, from, next) {
  if (!store.getters.loggedIn) {
    next({ name: 'login' });
    return;
  }
  next({ name: 'no-season' })
}

export default store;
