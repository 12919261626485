import { createRouter, createWebHistory } from 'vue-router'
import store from '@/FrontEnd/store/store.js';
import { inject,provide } from 'vue'



const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/FrontEnd/views/DashboardView.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/FrontEnd/views/ErrorView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/FrontEnd/views/LoginView.vue')
  },
  {
    path: '/mappings',
    name: 'mappings',
    component: () => import('@/FrontEnd/views/MappingView.vue')
  },
  { path: '/:catchAll(.*)', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach (async (to, from) => {


  if(!store.getters.isSessionValid && to.name !== 'login'){
    console.log("SESSION INVALID");
    return {
      name: 'login'
    }
  }
  if(!store.getters.loggedIn && to.name !== 'login'){
    return {
      name: 'login'
    }
  }

  return true;
})

export default router
